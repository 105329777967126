import Vue from "vue";
import VueRouter from "vue-router";

const loadComponent = (component) => {
  return () => import(`@/views/${component}.vue`);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: loadComponent("HomeView"),
  },
  {
    path: "/card",
    name: "card",
    component: loadComponent("CardView"),
  },
  {
    // path: "/privacy",
    // name: "privacy",
    path: "/aviso-de-privacidad",
    name: "aviso-de-privacidad",
    component: loadComponent("PrivacyView"),
  },
  {
    path: "/terminos-y-condiciones",
    name: "terminos-y-condiciones",
    component: loadComponent("TermsView"),
  },
  {
    path: "/delete-account",
    name: "delete",
    component: loadComponent("DeleteAccountView"),
  },
  {
    path: "/contacto",
    name: "contacto",
    component: loadComponent("ContactView"),
  },
  {
    path: "/redirect",
    name: "redirect",
    component: loadComponent("RedirectView"),
  },
  {
    path: "/tarjeta/terminos-y-condiciones",
    name: "CardTermsView",
    component: loadComponent("CardTermsView"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "instant" }
  },
});

export default router;
