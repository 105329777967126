import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './scss/index.scss'
import Vuelidate from "vuelidate";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(Vuelidate);

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SERVER_KEY,
  loaderOptions: {
    useEnterprise: true
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
